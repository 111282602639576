import { APP_DATE_FORMAT } from "../config/constants";
import moment from 'moment';

export const fmtUpdatedTime = (entity: any) =>
  entity && entity.baseInfo && entity.baseInfo.updatedTime ? moment(entity.baseInfo.updatedTime).format(APP_DATE_FORMAT) : '';

export const formatDate = (date: any) => moment(date).format(APP_DATE_FORMAT)

export const formatUnixTime = (totalSeconds: number) => {
  const calcDays = Math.floor(totalSeconds / (24 * 60 * 60))
  const calcHours = Math.floor((totalSeconds % (24 * 60 * 60)) / (60 * 60))
  const calcMinutes = Math.floor((totalSeconds % (60 * 60)) / 60)
  const calcSeconds = totalSeconds % 60
  const days = calcDays > 0 ? calcDays + ' ngày ' : ''
  const hours = calcHours > 0 ? calcHours + ' giờ ' : ''
  const minutes = calcMinutes > 0 ? calcMinutes + ' phút ' : ''
  const seconds = calcSeconds > 0 ? calcSeconds + ' giây' : ''
  return days + hours + minutes + seconds
}

export const formatEndTime = (startTime: any, seconds: any) => {
  const newDate = new Date(startTime)
  newDate.setSeconds(newDate.getSeconds() + seconds)
  return moment(newDate).format('YYYY-MM-DD HH:mm:ss')
}

export const FORMAT_DATE = 'YYYY-MM-DD'
export const FORMAT_TIME = 'HH:mm:ss'