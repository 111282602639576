import { useCallback, useEffect, useState } from "react"
import { apiAddListMaintainScheduler, apiDeleteListMaintainScheduler, apiGetAccountAffApiCurrent, apiGetAccountDataSponsorCurrent, apiGetListMaintainScheduler, apiGetProviderCurrent, apiGetStatusMaintain } from "../../api"
import { Button, Card, DatePicker, Divider, Input, Modal, TableProps, Tag, TimePicker } from "antd"
import dayjs from 'dayjs'
import { WHILELIST_ACCOUNT } from "../../config/constants"
import { FORMAT_DATE, FORMAT_TIME, formatEndTime, formatUnixTime } from "../../utils/entity-utils"
import { useSelector } from "react-redux";
import { authSelector } from "../../redux/slices/authSlice";
import ModalMaintain from "./ModalMaintain";
import ModalAccountDataSponsor from "./ModalAccountDataSponsor";
import ModalAccountAffapi from "./ModalAccountAffapi";
import ModalProvider from "./ModalProvider";
import CustomTable from "../../components/table"
import { toastError, toastSuccess } from "../../utils/toast"

export default function SystemPage() {
    const [canUpdate, setCanUpdate] = useState(false)
    const authState = useSelector(authSelector);
    const { account } = authState;
    const [maintainSchedulers, setMaintainSchedulers] = useState({ maintainSchedulers: [], total: 0 });
    const [maintainValue, setMaintainValue] = useState({ value: '', expireTime: 0 });
    const [providerValue, setProviderValue] = useState('');
    const [modalMaintain, setModalMaintain] = useState(false);
    const [accountDataSponsorValue, setAccountDataSponsorValue] = useState('');
    const [accountAffapiValue, setAccountAffapiValue] = useState('');
    const [modalAccountDataSponsor, setModalAccountDataSponsor] = useState(false);
    const [modalAccountAffapi, setModalAccountAffapi] = useState(false);
    const [modalProvider, setModalProvider] = useState(false);
    const [modalCreateScheduler, setModalCreateScheduler] = useState(false);
    const [createSchedulerValue, SetCreateSchedulerValue] = useState<any>({});
    const [modalDeleteScheduler, setModalDeleteScheduler] = useState<any>({ isOpen: false, data: null });
    const [otpConfirm, setOtpConfirm] = useState('');

    const columns: TableProps<any>['columns'] = [
        // {
        //     key: 'id',
        //     title: "id",
        //     dataIndex: 'id',
        // },
        {
            key: 'name',
            title: "Tên lịch trình",
            dataIndex: 'name',
        },
        {
            key: 'startTime',
            title: "Thời gian bắt đầu",
            dataIndex: 'startTime',
        },
        {
            key: 'endTime',
            title: "Thời gian kết thúc",
            render: (_, data) => data.expireTime ? formatEndTime(data.startTime, data.expireTime) : '-',
        },
        {
            key: 'expireTime',
            title: "Thời gian bảo trì",
            dataIndex: 'expireTime',
            render: expireTime => expireTime ? formatUnixTime(expireTime) : '-',
        },
        {
            key: 'note',
            title: "Ghi chú",
            dataIndex: 'note',
        },
        // {
        //     key: 'createdAt',
        //     title: "createdAt",
        //     dataIndex: 'createdAt',
        // },
        {
            key: 'createdBy',
            title: "Nhân sự",
            dataIndex: 'createdBy',
        },
        {
            key: 'action',
            title: "Chức năng",
            render: (_, data) => (
                <Button onClick={() => setModalDeleteScheduler({ isOpen: true, data })} danger>
                    Xoá
                </Button>
            )
        },
    ]

    const fetchSchedules = useCallback(async () => {
        try {
            const resp = await apiGetListMaintainScheduler()
            if (resp.data !== null) {
                setMaintainSchedulers(resp.data)
            }
        } catch (error) {
            console.log(error)
        }
    }, [])

    const fetchMaintain = useCallback(async () => {
        try {
            const resp = await apiGetStatusMaintain()
            if (resp.data !== null) {
                setMaintainValue(resp.data)
            }
        } catch (error) {
            console.log(error)
        }
    }, [])

    const fetchProvider = useCallback(async () => {
        try {
            const resp = await apiGetProviderCurrent()
            if (resp.data?.value !== null) {
                setProviderValue(resp.data.value)
            }
        } catch (error) {
            console.log(error)
        }
    }, [])

    const fetchAccountDataSponsor = useCallback(async () => {
        try {
            const resp = await apiGetAccountDataSponsorCurrent()
            if (resp.data?.value !== null) {
                setAccountDataSponsorValue(resp.data.value)
            }
        } catch (error) {
            console.log(error)
        }
    }, [])

    const fetchAccountAffapi = useCallback(async () => {
        try {
            const resp = await apiGetAccountAffApiCurrent()
            if (resp.data?.value !== null) {
                setAccountAffapiValue(resp.data.value)
            }
        } catch (error) {
            console.log(error)
        }
    }, [])

    const checkWhilelistAccount = useCallback(() => {
        const listWhilelistAccounts = WHILELIST_ACCOUNT?.split(',')
        if (listWhilelistAccounts?.includes(account.username)) {
            setCanUpdate(true)
        }
    }, [])

    useEffect(() => {
        checkWhilelistAccount()
        fetchMaintain()
        fetchProvider()
        fetchAccountDataSponsor()
        fetchAccountAffapi()
        fetchSchedules()
    }, [])

    const renderTag = (value: string, isActive = true) => (
        <Tag color={isActive ? "green" : "red"}>
            <h3 style={{ margin: 0, padding: 4 }}>{value}</h3>
        </Tag>
    )

    const renderMaintain = () => {
        if (maintainValue.value === 'true') {
            return renderTag(`Đang bảo trì${maintainValue.expireTime > 0 ? ` (${formatUnixTime(maintainValue.expireTime)})` : ''}`, false)
        }
        return renderTag('Đang hoạt động')
    }
    const renderProvider = () => {
        if (providerValue === 'ds_postpaid') {
            return renderTag('Affapi')
        }
        return renderTag('DataSponsor')
    }

    const renderAccountDataSponsor = () => {
        switch (accountDataSponsorValue) {
            case 'data_sponsor':
                return renderTag('SANN')
            case 'datasponsor_zynet':
                return renderTag('Zynet')
            default:
                return renderTag('Không tìm thấy', false)
        }
    }

    const renderAccountAffapi = () => {
        switch (accountAffapiValue) {
            case 'aff_sann':
                return renderTag('SANN')
            case 'aff_zynet':
                return renderTag('Zynet')
            default:
                return renderTag('Không tìm thấy', false)
        }
    }

    const handleCreateScheduler = async () => {
        try {
            if (!otpConfirm || !createSchedulerValue.startDay || !createSchedulerValue.startTime) {
                return toastError('Vui lòng nhập đầy đủ thông tin')
            }
            const startDay = dayjs(createSchedulerValue.startDay).format(FORMAT_DATE)
            let endDay = dayjs(createSchedulerValue.startDay).format(FORMAT_DATE)
            if (createSchedulerValue.endDay) {
                endDay = dayjs(createSchedulerValue.endDay).format(FORMAT_DATE)
            }
            const startTime = dayjs(createSchedulerValue.startTime).format(FORMAT_TIME)
            let endTime = dayjs(createSchedulerValue.startTime).format(FORMAT_TIME)
            if (createSchedulerValue.endTime) {
                endTime = dayjs(createSchedulerValue.endTime).format(FORMAT_TIME)
            }
            const startDate = new Date(`${startDay} ${startTime}`);
            const endDate = new Date(`${endDay} ${endTime}`);
            const expireTime = (endDate.getTime() - startDate.getTime()) / 1000
            if (expireTime < 0) {
                return toastError('Thời gian không hợp lệ')
            }
            const resp = await apiAddListMaintainScheduler({
                name: createSchedulerValue.name,
                startTime: `${startDay} ${startTime}`,
                expireTime: expireTime,
                note: createSchedulerValue.note,
                otpConfirm,
            })
            if (resp.status === 200) {
                fetchSchedules()
                toastSuccess('Đã thêm lịch trình bảo trì hệ thống')
                setOtpConfirm('')
                SetCreateSchedulerValue({})
                setModalCreateScheduler(false)
            }
        } catch (error) {
            toastError('Có lỗi xảy ra')
            console.log(error);
        }
    }

    const handleDeleteScheduler = async () => {
        try {
            const resp = await apiDeleteListMaintainScheduler({ id: modalDeleteScheduler.data?.id, otpConfirm })
            if (resp.status === 200) {
                fetchSchedules()
                toastSuccess('Đã xoá lịch trình bảo trì hệ thống')
                setOtpConfirm('')
                setModalDeleteScheduler({ isOpen: false, data: null })
            }
        } catch (error) {
            toastError('Có lỗi xảy ra')
            console.log(error);
        }
    }

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', gap: 20, flexWrap: 'wrap', width: '100%' }}>
                <div style={{ flex: 1 }}>
                    <Card title="Trạng thái hệ thống" bordered={true}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            {renderMaintain()}
                            {canUpdate && <Button onClick={() => setModalMaintain(true)} type="primary">Cập nhật</Button>}
                        </div>
                    </Card>
                </div>
                <div style={{ flex: 1 }}>
                    <Card title="Nhà cung cấp cho hệ thống hiện tại" bordered={true}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            {renderProvider()}
                            {canUpdate && <Button onClick={() => setModalProvider(true)} type="primary">Cập nhật</Button>}
                        </div>
                    </Card>
                </div>
                <div style={{ flex: 1 }}>
                    <Card title="Tài khoản data sponsor đang hoạt động" bordered={true}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            {renderAccountDataSponsor()}
                            {canUpdate && <Button onClick={() => setModalAccountDataSponsor(true)} type="primary">Cập nhật</Button>}
                        </div>
                    </Card>
                </div>
                <div style={{ flex: 1 }}>
                    <Card title="Tài khoản affapi đang hoạt động" bordered={true}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            {renderAccountAffapi()}
                            {canUpdate && <Button onClick={() => setModalAccountAffapi(true)} type="primary">Cập nhật</Button>}
                        </div>
                    </Card>
                </div>
            </div>
            <Divider />
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                <h3>Lịch trình bảo trì hệ thống</h3>
                <Button onClick={() => setModalCreateScheduler(true)}>
                    Thêm lịch trình
                </Button>
            </div>
            <CustomTable
                namePage='lịch trình'
                columns={columns}
                dataSource={maintainSchedulers?.maintainSchedulers}
                total={maintainSchedulers?.total}
            />
            <Modal
                title="Thêm lịch trình bảo trị hệ thống"
                open={modalCreateScheduler}
                onOk={() => alert()}
                onCancel={() => setModalCreateScheduler(false)}
                footer={[
                    <Button onClick={handleCreateScheduler} type="primary">
                        Thêm
                    </Button>
                ]}>
                <p>Tên lịch trình</p>
                <Input
                    value={createSchedulerValue.name}
                    onChange={e => SetCreateSchedulerValue({ ...createSchedulerValue, name: e.target.value })}
                />
                <div style={{ display: 'flex', gap: 10 }}>
                    <div style={{ width: '100%' }}>
                        <p><span style={{ color: 'red' }}>*</span> Ngày bắt đầu</p>
                        <DatePicker
                            style={{ width: '100%' }}
                            value={createSchedulerValue.startDay}
                            onChange={value => SetCreateSchedulerValue({ ...createSchedulerValue, startDay: value })}
                            format={FORMAT_DATE}
                            placeholder='Ngày bắt đầu'
                            picker='date'
                        />
                    </div>
                    <div style={{ width: '100%' }}>
                        <p>Ngày kết thúc</p>
                        <DatePicker
                            style={{ width: '100%' }}
                            value={createSchedulerValue.endDay}
                            onChange={value => SetCreateSchedulerValue({ ...createSchedulerValue, endDay: value })}
                            format={FORMAT_DATE}
                            placeholder='Ngày kết thúc'
                            picker='date'
                        />
                    </div>
                </div>
                <div style={{ display: 'flex', gap: 10 }}>
                    <div style={{ width: '100%' }}>
                        <p><span style={{ color: 'red' }}>*</span> Giờ bắt đầu</p>
                        <TimePicker
                            style={{ width: '100%' }}
                            value={createSchedulerValue.startTime}
                            onChange={value => SetCreateSchedulerValue({ ...createSchedulerValue, startTime: value })}
                            placeholder='Giờ bắt đầu'
                            format={FORMAT_TIME}
                        />
                    </div>
                    <div style={{ width: '100%' }}>
                        <p>Giờ kết thúc</p>
                        <TimePicker
                            style={{ width: '100%' }}
                            value={createSchedulerValue.endTime}
                            onChange={value => SetCreateSchedulerValue({ ...createSchedulerValue, endTime: value })}
                            placeholder='Giờ kết thúc'
                            format={FORMAT_TIME}
                        />
                    </div>
                </div>
                <p>Ghi chú</p>
                <Input
                    value={createSchedulerValue.note}
                    onChange={e => SetCreateSchedulerValue({ ...createSchedulerValue, note: e.target.value })}
                />
                <p><span style={{ color: 'red' }}>*</span> Nhập mã OTP xác nhận</p>
                <Input value={otpConfirm} onChange={e => setOtpConfirm(e.target.value)} />
                <Divider />
            </Modal>
            <Modal
                title="Xoá lịch trình bảo trị hệ thống"
                open={modalDeleteScheduler.isOpen}
                onOk={() => alert()}
                onCancel={() => setModalDeleteScheduler({ isOpen: false, data: null })}
                footer={[
                    <Button onClick={handleDeleteScheduler} type="primary">
                        Xoá
                    </Button>
                ]}>
                <p>Tên lịch trình: <b>{modalDeleteScheduler.data?.name}</b></p>
                <p>Thời gian bắt đầu: <b>{modalDeleteScheduler.data?.startTime}</b></p>
                <p>Thời gian kết thúc: <b>{modalDeleteScheduler.data?.expireTime ? formatEndTime(modalDeleteScheduler.data?.startTime, modalDeleteScheduler.data?.expireTime) : '-'}</b></p>
                <p>Thời gian bảo trì: <b>{modalDeleteScheduler.data?.expireTime ? formatUnixTime(modalDeleteScheduler.data?.expireTime) : '-'}</b></p>
                <p>Ghi chú: <b>{modalDeleteScheduler.data?.note}</b></p>
                <p>Nhân sự: <b>{modalDeleteScheduler.data?.createdBy}</b></p>
                <p><span style={{ color: 'red' }}>*</span> Nhập mã OTP xác nhận</p>
                <Input value={otpConfirm} onChange={e => setOtpConfirm(e.target.value)} />
                <Divider />
            </Modal>
            <ModalMaintain
                isOpen={modalMaintain}
                setIsOpen={setModalMaintain}
                data={maintainValue}
                fetchData={fetchMaintain}
            />
            <ModalProvider
                isOpen={modalProvider}
                setIsOpen={setModalProvider}
                data={providerValue}
                fetchData={fetchProvider}
            />
            <ModalAccountDataSponsor
                isOpen={modalAccountDataSponsor}
                setIsOpen={setModalAccountDataSponsor}
                data={accountDataSponsorValue}
                fetchData={fetchAccountDataSponsor}
            />
            <ModalAccountAffapi
                isOpen={modalAccountAffapi}
                setIsOpen={setModalAccountAffapi}
                data={accountAffapiValue}
                fetchData={fetchAccountAffapi}
            />
        </div>
    )
}
