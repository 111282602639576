import axios from 'axios';
import qs from 'qs';

const isFlattenable = (value: any) => value && typeof value === 'object' && !Array.isArray(value) && !(value instanceof String);

// Flatten params to make query string adapt with backend when params contains nested objects.
// Ex: "{a: {b: 1, c: {d: 2, e: 4}}, f: 1}" will output: "{a.b: 1, a.c.d: 2, a.c.e: 4, f: 1}"
// const flattenParams = (params: any) => {g
//   if (!isFlattenable(params)) {
//     return params;
//   }
//
//   const res = {};
//   for (const [k, v] of Object.entries(params)) {
//     if (!isFlattenable(v)) {
//       res[k] = v;
//       continue;
//     }
//
//     const nestedObj = flattenParams(v);
//     for (const [nestedKey, nestedValue] of Object.entries(nestedObj)) {
//       res[k + '.' + nestedKey] = nestedValue;
//     }
//   }
//
//   return res;
// };

const withPagination = (params: any) => {
  params = params || {};

  const { page, size } = params;
  if (page && size) {
    params['page.index'] = page;
    params['page.size'] = size;
    params['page.total'] = true;

    delete params['page'];
    delete params['size'];
  } else {
    params['page.index'] = 1;
    params['page.size'] = 999;
  }

  return params;
};

const cleanParams = (params: any) => {
  for (let [key, value] of Object.entries(params)) {
    // If value is array string, filter empty string and duplicate value
    if (Array.isArray(value) && value.every(it => typeof it === 'string')) {
      let newValue = value.filter(it => it);
      params[key] = Array.from(new Set(newValue as string[]));
    }

    // Remove all string value that equals "", undefined, null.
    if (typeof value === 'string' && !value) {
      delete params[key];
    }
  }

  return params;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  get: (url: string, params?: any, respType?: any) => {
    params = params || {};
    // params = withPagination(params);
    params = cleanParams(params);
    // params = flattenParams(params);

    return axios.get(url, {
      params,
      paramsSerializer: pr => qs.stringify(pr, { arrayFormat: 'repeat' }),
      responseType: respType,
    }
    );
  },
  post: (url: string, data: any) => axios.post(url, data),
  put: (url: string, data?: any) => axios.put(url, data),
  patch: (url: string, data: any) => axios.patch(url, data),
  delete: (url: string, data?: any) => axios.delete(url, { data }),
};
