import { useCallback, useEffect, useRef, useState } from 'react'
import {
    apiCheckDataCode, apiCheckDataCodeOnDataSponsor,
    apiGetCheckTransaction,
    apiGetCheckTransactionDataCode,
    apiGetCheckTransactionOnDataSponsor,
    apiGetCheckTransactionOnVTVCab,
    apiGetListPartner,
    apiGetTransactionRefund,
    apiRefundTransaction
} from '../../api'
import {CheckDataCodeResponse, CheckTransactionResponse} from '../../interfaces/transactions.interface'
import {Button, DatePicker, type DatePickerProps, InputNumber, Modal, Space, Table, TableProps, Tabs, TabsProps} from 'antd'
import { PartnerList } from '../../interfaces/list-partner.interface';
import { useLoading } from '../../context/LoadingContext';
import { Select, Input } from 'antd';
import { toast } from 'react-toastify';
import { toastError, toastSuccess } from '../../utils/toast';
import dayjs, {Dayjs} from "dayjs";
import defaultParams from "../../assets/constants/default_params";

const initCusgomer = {
    partners: [],
    total: 0
}

export default function Transactions() {
    const [customers, setCustomers] = useState<PartnerList>(initCusgomer)
    const [data, setData] = useState<CheckTransactionResponse>();
    const [selectedCustomer, setSelectedCustomer] = useState<number>(0);
    const [tranId, setTranId] = useState('');
    const selectRef = useRef(null);
    const [dataCode, setDataCode] = useState<CheckDataCodeResponse>();
    const [selectedCustomerDataCode, setSelectedCustomerCode] = useState<number>(0);
    const [dataCodeSerial, setDataCodeSerial] = useState('');
    const selectCodeRef = useRef(null);
    const [tranRefund, setTranRefund] = useState<any>({
        total: 0,
        listTrans: [],
    });
    const [selectedCustomerDataRefund, setSelectedCustomerRefund] = useState<number>(0);
    const selectRefundRef = useRef(null);
    const [modalSponsor, setModalSponsor] = useState<{ data: any, isOpen: boolean }>({
        data: null,
        isOpen: false,
    })
    const [cofirmCode, setConfirmCode] = useState<string>('')
    const [selectedDate, setSelectedDate] = useState(dayjs());
    const [resultOnDataSponsor, setResultOnDataSponsor] = useState('')
    const [resultDataCodeOnDataSponsor, setResultDataCodeOnDataSponsor] = useState('')
    const [timeRefund, setTimeRefund] = useState<any>();

    useEffect(() => {
        const now = new Date()
        const year = now.getFullYear()
        const month = now.getMonth() + 1 < 10 ? `0${now.getMonth() + 1}` : now.getMonth() + 1
        setTimeRefund(dayjs(`${year}-${month}`, 'YYYY-MM'))
    }, [])

    // Loading context 
    const { setIsLoading } = useLoading();

    const sponsorColumns: TableProps<any>['columns'] = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'TranId',
            dataIndex: 'tranId',
            key: 'tranId',
        },
        {
            title: 'ReqTime',
            dataIndex: 'reqTime',
            key: 'reqTime',
            render: (reqTime: number) => dayjs(reqTime).format("YYYY-MM-DD HH:mm:ss"),
        },
        {
            title: 'Platform',
            dataIndex: 'platform',
            key: 'platform',
        },
        {
            title: 'Msisdn',
            dataIndex: 'msisdn',
            key: 'msisdn',
        },
        {
            title: 'PackageName',
            dataIndex: 'packageName',
            key: 'packageName',
        },
        {
            title: 'DataPack',
            dataIndex: 'dataPack',
            key: 'dataPack',
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'CpCode',
            dataIndex: 'cpCode',
            key: 'cpCode',
        },
        {
            title: 'Return',
            dataIndex: 'return',
            key: 'return',
            render: (refund: string) => refund === 'Refund' ? '' : refund,
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            render: (_, data: any) => data.type === 'REGISTER_COMBO_PROMOTION' ? 0 : data.price,
        },
        {
            key: 'action',
            title: 'Action',
            align: 'center',
            render: (_, data: any) => (<Space>
                    <Button
                        disabled={data.return === 'Refund' || data.type === 'REGISTER_COMBO_PROMOTION'}
                        onClick={() => setModalSponsor({ data, isOpen: true })}
                        type='primary'
                    >
                        Refund
                    </Button>
                    <Button onClick={()=>checkTransactionOnDataSponsor(data)}
                    >
                       Kiểm tra
                    </Button>
            </Space>

            ),
        },
    ];

    const codeColumns: TableProps<any>['columns'] = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'TranId',
            dataIndex: 'tranId',
            key: 'tranId',
        },
        {
            title: 'createdAt',
            dataIndex: 'createdAt',
            key: 'createdAt',
        },
        {
            title: 'packageCode',
            dataIndex: 'packageCode',
            key: 'packageCode',
        },
        {
            title: 'Serial',
            dataIndex: 'dataCodeSerial',
            key: 'dataCodeSerial',
        },
        {
            title: 'DataCode',
            dataIndex: 'dataCode',
            key: 'dataCode',
        },
        {
            title: 'CpCode',
            dataIndex: 'cpCode',
            key: 'cpCode',
        },
        {
            title: 'startDate',
            dataIndex: 'startDate',
            key: 'startDate',
        },
        {
            title: 'endDate',
            dataIndex: 'endDate',
            key: 'endDate',
        },
        {
            key: 'action',
            title: 'Action',
            align: 'center',
            render: (_, data: any) => (<>
                    <Button onClick={checkDataCodeOnDataSponsor}
                            type='primary'
                    >
                        Kiểm tra
                    </Button>
                </>

            ),
        },
    ];

    const refundColumns: TableProps<any>['columns'] = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'TranId',
            dataIndex: 'tranId',
            key: 'tranId',
        },
        {
            title: 'ReqTime',
            dataIndex: 'reqTime',
            key: 'reqTime',
            render: (reqTime: number) => dayjs(reqTime).format("YYYY-MM-DD HH:mm:ss"),
        },
        {
            title: 'Platform',
            dataIndex: 'platform',
            key: 'platform',
        },
        {
            title: 'Msisdn',
            dataIndex: 'msisdn',
            key: 'msisdn',
        },
        {
            title: 'PackageName',
            dataIndex: 'packageName',
            key: 'packageName',
        },
        {
            title: 'DataPack',
            dataIndex: 'dataPack',
            key: 'dataPack',
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'CpCode',
            dataIndex: 'cpCode',
            key: 'cpCode',
        },
        {
            title: 'Return',
            dataIndex: 'return',
            key: 'return',
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
        },
    ];

    useEffect(() => {
        fetchApiGetListPartner()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function fetchTransactions(cpCode: string, tranId: string) {
        try {
            const date = new Date();
            const resTransactions = await apiGetCheckTransaction({
                cpCode: cpCode,
                tranId: tranId,
                reqTime: date.getTime(),
                date:  selectedDate.format(defaultParams.FORMAT_DATE),
            })
            console.log('resTransactions', resTransactions);
            if (!resTransactions?.data?.listTrans || resTransactions?.data?.listTrans?.length <= 0) {
                toast.success('Không có kết quả trả về!')
                return setData({
                    tranId: '',
                    return: '',
                    description: '',
                    detailCode: '',
                    total: 0,
                    listTrans: [],
                })
            }
            return setData(resTransactions.data)
        } catch (error) {
            console.log('error', error);
            toast.error('Có lỗi xảy ra!')
            setData({
                tranId: '',
                return: '',
                description: '',
                detailCode: '',
                total: 0,
                listTrans: [],
            })
        }
    }

    async function fetchTransactionsOnDataDataSponsor(cpCode: string, tranId: string, reqTime:string, type?: string) {
        try {
            const date = new Date();
            if (type === "REGISTER_COMBO_PROMOTION" || type === "REGISTER_SERVICE") {
                const resVTVCab = await apiGetCheckTransactionOnVTVCab({
                    cpCode: cpCode,
                    tranId: tranId,
                    reqTime: date.getTime(),
                    date: reqTime,
                })
                console.log('resVTVCab', resVTVCab );
                if (!resVTVCab.data?.result) {
                    toast.success('Không có kết quả trả về!')
                    return setResultOnDataSponsor('')
                }
                return setResultOnDataSponsor(resVTVCab.data?.result)
            }
            const resTransactions = await apiGetCheckTransactionOnDataSponsor({
                cpCode: cpCode,
                tranId: tranId,
                reqTime: date.getTime(),
                date: reqTime,
            })
            console.log('resTransactions', resTransactions);
            if (!resTransactions.data?.result) {
                toast.error('Không có kết quả trả về!')
                setResultOnDataSponsor('')
            }
            return setResultOnDataSponsor(resTransactions.data.result)
        } catch (error) {
            console.log('error', error);
            toast.error('Vui lòng kiểm tra lại thông tin')
            setResultOnDataSponsor('')
        }
    }

    async function fetchDataCodeOnDataDataSponsor(cpCode: string, dataCodeSerial: string) {
        try {
            const date = new Date();
            const resTransactions = await apiCheckDataCodeOnDataSponsor({
                cpCode: cpCode,
                dataCodeSerial: dataCodeSerial,
                reqTime: date.getTime(),
                date:  selectedDate.format(defaultParams.FORMAT_DATE),
            })
            console.log('resTransactions', resTransactions);
            if (!resTransactions.data?.result) {
                toast.error('Không có kết quả trả về!')
                return setResultOnDataSponsor('')
            } 
            return setResultOnDataSponsor(resTransactions.data.result)
        } catch (error) {
            console.log('error', error);
            toast.error('Có lỗi xảy ra!')
            setResultOnDataSponsor('')
        }
    }


    async function fetchDataCode(cpCode: string, tranIdCode: string) {
        try {
            const date = new Date();
            const resDataCodes = await apiCheckDataCode({
                cpCode: cpCode,
                dataCodeSerial: tranIdCode,
                reqTime: date.getTime(),
                date: selectedDate.format(defaultParams.FORMAT_DATE),
            })
            console.log('resDataCodes', resDataCodes);
            if (!resDataCodes.data?.dataCodeModels ||resDataCodes.data?.dataCodeModels?.length <= 0) {
                toast.success('Không có kết quả trả về!')
                return setDataCode({
                    total: 0,
                    dataCodeModels: [],
                })
            }
            return setDataCode(resDataCodes.data)
        } catch (error) {
            console.log('error', error);
            toast.error('Có lỗi xảy ra!')
            setDataCode({
                total: 0,
                dataCodeModels: [],
            })
        }
    }

    async function fetchTranRefund(cpCode: string, year: number, month: number) {
        try {
            const respTranRefund = await apiGetTransactionRefund({ cpCode, year, month })
            console.log('respTranRefund', respTranRefund);
            if (!respTranRefund.data?.listTrans ||respTranRefund.data?.listTrans.length <= 0) {
                toast.success('Không có kết quả trả về!')
                return setTranRefund({
                    total: 0,
                    listTrans: [],
                })
            }
            return setTranRefund(respTranRefund.data)
        } catch (error) {
            console.log('error', error);
            toast.error('Có lỗi xảy ra!')
            setTranRefund({
                total: 0,
                listTrans: [],
            })
        }
    }

    const fetchApiGetListPartner = useCallback(async () => {
        try {
            setIsLoading(true)
            const resCustomers = await apiGetListPartner()
            console.log("resCustomers", resCustomers.data.partners)
            if (resCustomers.data.partners != null) {
                setCustomers(resCustomers.data)
            } else {
                setCustomers(initCusgomer)
            }
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log(error)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const checkTransactionEvent = async () => {
        setResultOnDataSponsor("")
        const cpCode = customers.partners[Number(selectedCustomer)]?.cpCode ?? '';
        if (!cpCode || !tranId) {
            toast.error('Vui lòng nhập đầy đủ thông tin')
            return
        }
        try {
            setIsLoading(true)
            await fetchTransactions(cpCode, tranId)
            setIsLoading(false)
        } catch (error) {
            toast.error('Vui lòng kiểm tra lại thông tin')
            setIsLoading(false)
        }
    }

    const checkTransactionOnDataSponsor = async (data: any) => {
        console.log("JJJ", data)
        const cpCode = customers.partners[Number(selectedCustomer)]?.cpCode ?? '';
        if (!cpCode || !tranId) {
            toast.error('Vui lòng nhập đầy đủ thông tin')
            return
        }
        try {
            setIsLoading(true)
            setTranId(data.tranId)
            const reqTime = dayjs(data.reqTime).format("YYYY-MM-DD")
            await fetchTransactionsOnDataDataSponsor(cpCode, data.tranId, reqTime, data.type)
            setIsLoading(false)
        } catch (error) {
            toast.error('Vui lòng kiểm tra lại thông tin')
            setIsLoading(false)
        }
    }

    const checkDataCodeOnDataSponsor = async () => {
        const cpCode = customers.partners[Number(selectedCustomer)]?.cpCode ?? '';
        if (!cpCode || !dataCodeSerial) {
            toast.error('Vui lòng nhập đầy đủ thông tin')
            return
        }
        try {
            setIsLoading(true)
            await fetchDataCodeOnDataDataSponsor(cpCode, dataCodeSerial)
            setIsLoading(false)
        } catch (error) {
            toast.error('Vui lòng kiểm tra lại thông tin')
            setIsLoading(false)
        }
    }

    const checkDataCode = async () => {
        const cpCode = customers.partners[Number(selectedCustomerDataCode)]?.cpCode ?? '';
        if (!cpCode || !dataCodeSerial) {
            toast.error('Vui lòng nhập đầy đủ thông tin')
            return
        }
        try {
            setIsLoading(true)
            await fetchDataCode(cpCode, dataCodeSerial)
            setIsLoading(false)
        } catch (error) {
            toast.error('Vui lòng kiểm tra lại thông tin')
            setIsLoading(false)
        }
    }

    const checkRefund = async () => {
        const cpCode = customers.partners[Number(selectedCustomerDataRefund)]?.cpCode ?? '';
        const time = new Date(timeRefund)
        const year = time?.getFullYear()
        const month = time?.getMonth() + 1
        if (!cpCode || year <= 0 || month <= 0) {
            return toast.error('Vui lòng nhập đầy đủ thông tin')
        }
        try {
            setIsLoading(true)
            await fetchTranRefund(cpCode, year, month)
            setIsLoading(false)
        } catch (error) {
            toast.error('Vui lòng kiểm tra lại thông tin')
            setIsLoading(false)
        }
    }

    const onChangeDate: DatePickerProps['onChange'] = (date, dateString) => {
        setSelectedDate(date as Dayjs);
        console.log(date, dateString);
    };

    const clearEvent = () => {
        setData({} as CheckTransactionResponse);
        setTranId('')
        setSelectedCustomer(0);
        setResultOnDataSponsor("")
    }

    const clearEventCode = () => {
        setDataCode({} as CheckTransactionResponse);
        setDataCodeSerial('')
        setSelectedCustomerCode(0);
    }

    const handleRefundTransaction = async () => {
        try {
            const { tranId, cpCode, day, month, year } = modalSponsor.data
            setIsLoading(true)
            const resp = await apiRefundTransaction({ tranId, cpCode, day, month, year, otpConfirm: cofirmCode })
            if (resp.status === 200) {
                toastSuccess('Hoàn trả giao dịch thành công!')
            }
            setModalSponsor({ data: null, isOpen: false })
            setConfirmCode('')
            setIsLoading(false)
        } catch (error) {
            toastError('Vui lòng kiểm tra lại thông tin!')
            setIsLoading(false)
        }
    }

    const itemsTab: TabsProps['items'] = [
        {
            key: 'data-sponsor',
            label: 'Data sponsor',
            children: (
                <div style={{ overflow: 'auto' }}>
                    <div style={{ display: 'flex', alignItems: 'baseline' }}>
                        <div style={{ display: 'flex', marginBottom: 10, marginRight: 30, alignItems: 'baseline' }}>
                            <span style={{ width: 80 }}>Chọn đối tác</span>
                            <Select
                                ref={selectRef}
                                showSearch
                                style={{ flex: 1, marginBottom: 16, minWidth: '300px' }}
                                placeholder="Chọn khách hàng"
                                optionFilterProp="name"
                                onChange={(value) => setSelectedCustomer(value)}
                                filterOption={(input: any, option: any) =>
                                    option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                value={selectedCustomer}
                            >
                                {customers?.partners?.map((customer: any, idx: React.Key) => (
                                    <Select.Option key={idx} value={idx}>
                                        {customer.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', marginRight: 10, }}>
                            <span style={{ width: 90, marginRight: 10, }}>Transaction Id</span>
                            <Input
                                placeholder="Nhập mã giao dịch"
                                value={tranId}
                                onChange={(event) => setTranId(event.target.value)}
                                style={{ width: '300px', margin: '10px 0' }}
                            />
                        </div>
                        <Button type="primary" block style={{ width: 100, marginRight: 10 }} onClick={checkTransactionEvent}>Tìm kiếm</Button>
                        <Button danger block style={{ width: 100 }} onClick={clearEvent}>Clear</Button>
                    </div>
                    <div style={{display: 'flex', marginBottom: 10, marginRight: 30, alignItems: 'baseline'}}>
                        <span style={{width: 80}}>Chọn thời gian</span>
                        <Space direction="vertical">
                            <DatePicker
                                allowClear={false}
                                onChange={onChangeDate}
                                value={selectedDate}
                                picker = "date"
                            />
                            </Space>
                    </div>
                    <Table
                        dataSource={data?.listTrans}
                        columns={sponsorColumns}
                        rowKey={(record) => record.id}
                    />
                    {resultOnDataSponsor.length >0 && <div style={{width:"100%"}}>
                        <strong>Kết quả kiểm tra của giao dịch {tranId}</strong>
                        <pre style={{display:"block", width:"100%",whiteSpace:"pre-wrap"}}>
                            {resultOnDataSponsor}
                        </pre>
                    </div>}
                </div>
            ),
        },
        {
            key: 'data-code',
            label: 'Data code',
            children: (
                <div style={{overflow: 'auto'}}>
                    <div style={{display: 'flex', alignItems: 'baseline'}}>
                        <div style={{display: 'flex', marginBottom: 10, marginRight: 30, alignItems: 'baseline'}}>
                            <span style={{width: 80}}>Chọn đối tác</span>
                            <Select
                                ref={selectCodeRef}
                                showSearch
                                style={{flex: 1, marginBottom: 16, minWidth: '300px'}}
                                placeholder="Chọn khách hàng"
                                optionFilterProp="name"
                                onChange={(value) => setSelectedCustomerCode(value)}
                                filterOption={(input: any, option: any) =>
                                    option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                value={selectedCustomerDataCode}
                            >
                                {customers?.partners?.map((customer: any, idx: React.Key) => (
                                    <Select.Option key={idx} value={idx}>
                                        {customer.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </div>
                        <div style={{display: 'flex', alignItems: 'center', marginRight: 10,}}>
                            <span style={{width: 90, marginRight: 10,}}>Data Code Serial</span>
                            <Input
                                placeholder="Nhập Data Code Serial"
                                value={dataCodeSerial}
                                onChange={(event) => setDataCodeSerial(event.target.value)}
                                style={{width: '300px', margin: '10px 0'}}
                            />
                        </div>
                        <Button type="primary" block style={{width: 100, marginRight: 10}} onClick={checkDataCode}>Tìm
                            kiếm</Button>
                        <Button danger block style={{width: 100}} onClick={clearEventCode}>Clear</Button>
                    </div>
                    <div style={{display: 'flex', marginBottom: 10, marginRight: 30, alignItems: 'baseline'}}>
                        <span style={{width: 80}}>Chọn thời gian</span>
                        <Space direction="vertical">
                            <DatePicker
                                allowClear={false}
                                onChange={onChangeDate}
                                value={selectedDate}
                                picker="date"
                            />
                        </Space>
                    </div>
                    <Table
                        dataSource={dataCode?.dataCodeModels}
                        columns={codeColumns}
                        rowKey={(record) => record.id}
                    />
                    {resultOnDataSponsor.length >0 && <div style={{width:"100%"}}>
                        <strong>Kết quả kiểm tra của giao dịch {dataCodeSerial}</strong>
                        <pre style={{display: "block", width: "100%", whiteSpace: "pre-wrap"}}>
                            {resultOnDataSponsor}
                        </pre>
                    </div>}
                </div>
            ),
        },
        {
            key: 'refund',
            label: 'Refund',
            children: (
                <div style={{ overflow: 'auto' }}>
                    <div style={{ display: 'flex', alignItems: 'baseline' }}>
                        <div style={{ display: 'flex', marginBottom: 10, marginRight: 30, alignItems: 'baseline' }}>
                            <span style={{ width: 80 }}>Chọn đối tác</span>
                            <Select
                                ref={selectRefundRef}
                                showSearch
                                style={{ flex: 1, marginBottom: 16, minWidth: '300px' }}
                                placeholder="Chọn khách hàng"
                                optionFilterProp="name"
                                onChange={(value) => setSelectedCustomerRefund(value)}
                                filterOption={(input: any, option: any) =>
                                    option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                value={selectedCustomerDataRefund}
                            >
                                {customers?.partners?.map((customer: any, idx: React.Key) => (
                                    <Select.Option key={idx} value={idx}>
                                        {customer.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', marginRight: 20 }}>
                            <span style={{ marginRight: 10, }}>Thời gian</span>
                            <DatePicker
                                allowClear={false}
                                onChange={value => setTimeRefund(dayjs(value, 'YYYY-MM'))}
                                value={timeRefund}
                                format={'YYYY-MM'}
                                picker="month"
                            />
                        </div>
                        <Button type="primary" block style={{ width: 100, marginRight: 10 }} onClick={checkRefund}>Tìm
                            kiếm</Button>
                        <Button danger block style={{ width: 100 }} onClick={clearEventCode}>Clear</Button>
                    </div>
                    <Table
                        dataSource={tranRefund?.listTrans}
                        columns={refundColumns}
                        rowKey={(record) => record.id}
                    />
                </div>
            ),
        },
    ];
    const onChange = (key: string) => {
        clearEvent()
        clearEventCode()
    };

    return <div>
        <Tabs defaultActiveKey="data-sponsor" items={itemsTab} onChange={onChange}></Tabs>
        <Modal title='Refund sponsor' open={modalSponsor.isOpen}
               onCancel={() => {
                   setModalSponsor({data: null, isOpen: false})
                   setConfirmCode('')
               }}
               footer={[
                   <Button onClick={handleRefundTransaction} type='primary'>
                       Refund
                   </Button>,
               ]}>
            <div style={{ marginBottom: 20 }}>
                Bạn có chắc muốn hoàn trả tiền giao dịch <b>{modalSponsor.data?.tranId}</b> cho đối tác <b>{modalSponsor.data?.cpCode}</b>?
            </div>
            <Input value={cofirmCode} onChange={e => setConfirmCode(e.target.value)} />
        </Modal>
    </div>
}
